export enum CategoryEnum {
  USER = 'User',
  SYSTEM = 'System',
  APPLICATION_FORM = 'Application Form'
}

export enum ActionEnum {
  PAGE_VIEW = 'page_view',
  CLICK = 'click',
  LOGIN = 'sign_in',
  OAUTH_LOGIN = 'oAuth-login',
  SUMMIT_FORM = 'submit_form',
  VIEW = 'view',
  DETAIL_PAGE_LOADED = 'detail_page_loaded',
  OTP_VERIFICATION_STEP_LOADED = 'otp_verification_step_loaded',
  PERSONAL_INFO_STEP_LOADED = 'personal_info_step_loaded',
  MOVE_IN_DATE_STEP_LOADED = 'move_in_date_step_loaded',
  BACKGROUND_INFO_STEP_LOADED = 'background_info_step_loaded',
  PAYMENT_STEP_LOADED = 'payment_step_loaded',
  SIGN_OR_MEET_ROOMMATE_STEP_LOADED = 'sign_or_meet_roommate_step_loaded',
  SIGN_LEASE_CHOSEN = 'sign_lease_chosen',
  SIGN_LEASE_URL_OPENED = 'sign_lease_url_opened',
  SIGN_LEASE_SIGNED = 'sign_lease_signed',
  DRIVER_LICENSE_STEP_LOADED = 'driver_license_step_loaded',
  MEET_ROOMMATE_FIRST_STEP_LOADED = 'meet_roommate_first_step_loaded',
  REFERRAL_SOURCE_STEP_LOADED = 'referral_source_step_loaded',
  APPLICATION_FORM_SUBMITTED = 'application_form_submitted',
  CAROUSEL_NEXT_IMAGE_CLICKED = 'carousel_next_image_clicked',
  CAROUSEL_PREVIOUS_IMAGE_CLICKED = 'carousel_previous_image_clicked',
  LIGHT_BOX_NEXT_IMAGE_CLICKED = 'light_box_next_image_clicked',
  LIGHT_BOX_PREVIOUS_IMAGE_CLICKED = 'light_box_previous_image_clicked',
  PAYSTUB_STEP_LOADED = 'paystub_step_loaded'
}

export enum PageRouteEnum {
  HOUSE_DETAIL = 'house-detail',
  ROOM_DETAIL = 'room-detail'
}
